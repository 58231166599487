import React, { useRef, useState } from "react";
import { IoMdTime } from "react-icons/io";

export function TimePicker(props) {
    const [isFocus, setIsFocus] = useState(false);
    const dateRef = useRef();

    function handleShowPopup(e) {
        if (dateRef) {
            dateRef.current.showPicker();
            setIsFocus(true);
        }
    }

    function handleDateInputChange(e) {
        props.onTimeChange(e.target.value);
    }

    return (
        <div className="relative flex">
            <div className={"flex w-fit h-[30px] items-center border-[1px] rounded-sm cursor-pointer " + (isFocus ? " border-[#0073CD]" : " border-[#707070]")}
                tabIndex={-1}
                onBlur={() => { setIsFocus(false); }}
                onClick={handleShowPopup}>
                <input type="text" className="h-full w-[50px] py-[0.8px] px-1 outline-none cursor-pointer"
                    value={props.value}
                    readOnly={true} />
                <IoMdTime className=" mr-1" style={{ color: 'black', fontSize: '18px' }} />
            </div>
            <input ref={dateRef} type="time"
                value={props.value}
                className={"absolute w-0 h-0 opacity-0 " + props.showPickerPositionCss}
                onChange={handleDateInputChange} />
        </div>
    );
}

TimePicker.defaultProps = {
    value: "",
    showPickerPositionCss: "mt-[35px]",
    onTimeChange: () => { void (0) },
}