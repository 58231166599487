import { variables, StatusCode, CompanyLoginPath, UserLoginPath, OperationPath, OperationLoginPath, OperationStartWorkPath, ImageUrlYuukoukikan, UrlPath, HindoKaisuSetting, ManageItemType } from "../../Variables";
import { generatePath } from "react-router";
import { appInsights } from "./ApplicationInsight";

export const urlCompanyName = GetUrlCompany();
const axios = require('axios');
const chunkSize = 1048576; // 1MB
const retries = 5;
let fileSize = 0;
let fileName = "";

/**
 * UTCからLocalDateTimeへ変換する。
 * @param {*} strDate 
 * @returns 
 */
export function ToLocalDateTime(strDate) {
	let result = "";
	if (strDate) {
		const date = new Date(strDate + "Z");
		if (date) {
			result = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, 0)}/${date.getDate().toString().padStart(2, 0)} ${date.getHours().toString().padStart(2, 0)}:${date.getMinutes().toString().padStart(2, 0)}`;
		} else {
			result = strDate;
		}
	}
	return result;
}

/**
 * UTCからLocalDateへ変換する。
 * @param {*} strDate 
 * @returns 
 */
export function ToLocalDate(strDate) {
	let result = "";
	if (strDate) {
		const date = new Date(strDate + "Z");
		if (date) {
			result = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, 0)}/${date.getDate().toString().padStart(2, 0)}`;
		} else {
			result = strDate;
		}
	}
	return result;
}

/**
 * DateFormatを変更する。
 * date string -> YYYY/MM/DD HH:MM:SS
 * @param {*} date 
 * @returns 
 */
export function DisplayDateFormatChange(date) {
	let result = "";
	if (date) {
		result = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, 0)}/${date.getDate().toString().padStart(2, 0)} ${date.getHours().toString().padStart(2, 0)}:${date.getMinutes().toString().padStart(2, 0)}:${date.getSeconds().toString().padStart(2, 0)}`;
	} else {
		result = date;
	}
	return result;
}

/**
 * UTCへ変換する。
 * @param {*} date 
 * @returns 
 */
export function ToUTCDateTime(date) {
	let result = "";
	if (date) {
		result = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, 0)}-${date.getUTCDate().toString().padStart(2, 0)} ${(date.getUTCHours()).toString().padStart(2, 0)}:${(date.getUTCMinutes()).toString().padStart(2, 0)}:${(date.getUTCSeconds()).toString().padStart(2, 0)}`;
	} else {
		result = date;
	}
	return result;
}

/**
 * UTCからLocalDateTime(MM/dd hh:mm)フォーマットへ変換する。
 * @param {*} strDate 
 * @returns 
 */
export function ToLocalDayAndTime(strDate) {
	let result = "";
	if (strDate) {
		const date = new Date(strDate + "Z");
		if (date) {
			result = `${(date.getMonth() + 1).toString().padStart(2, 0)}/${date.getDate().toString().padStart(2, 0)} ${date.getHours().toString().padStart(2, 0)}:${date.getMinutes().toString().padStart(2, 0)}`;
		} else {
			result = strDate;
		}
	}
	return result;
}

/**
 * date string -> YYYY/MM/DD
 * @param {*} dateValue 
 * @returns 
 */
export function ConvertDisplayDateFormat(dateValue) {
	let result = "";
	const date = new Date(dateValue);
	if (date) {
		result = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, 0)}/${date.getDate().toString().padStart(2, 0)}`;
	}
	return result;
}

/**
 * date string -> YYYY/MM/DD HH:MM
 * @param {*} dateValue 
 * @returns 
 */
export function ConvertDisplayDateTimeFormat(dateValue) {
	let result = "";
	const date = new Date(dateValue);
	if (date) {
		result = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, 0)}/${date.getDate().toString().padStart(2, 0)} ${date.getHours().toString().padStart(2, 0)}:${date.getMinutes().toString().padStart(2, 0)}`;
	}
	return result;
}

/**
 * ファイルのアップロード
 * @param {*} file 
 * @param {*} name 
 * @param {*} yuukoukikan 
 * @param {*} urlpath 
 */
export async function FileUpload(file, name, yuukoukikan = ImageUrlYuukoukikan.ShortTerm, urlpath = UrlPath.Editor) {
	let objUrl = null;
	try {
		await Validate(urlpath);
		fileName = name;
		fileSize = file.size;
		let totalChunkCount = fileSize % chunkSize === 0 ? fileSize / chunkSize : Math.floor(fileSize / chunkSize) + 1;
		let beginingOfTheChunk = 0;
		let endOfTheChunk = chunkSize;
		let counter = 0;
		for (let i = 0; i < totalChunkCount; i++) {
			counter++;
			var chunk = file.slice(beginingOfTheChunk, endOfTheChunk);
			let response = await UploadChunk(chunk, counter);
			if (response) {
				const data = response.data;
				if (data.isSuccess) {
					if (counter === totalChunkCount) {
						objUrl = await UploadCompleted(yuukoukikan);
					} else {
						beginingOfTheChunk = endOfTheChunk;
						endOfTheChunk = endOfTheChunk + chunkSize;
					}
				} else {
					throw (data.errorMessage);
				}
			} else {
				const error = "Max retries reached.";
				throw (error);
			}
		}
	} catch (error) {
		throw (error);
	}
	return objUrl;
}

/**
 * 大きなファイルを小さな断片に分割してアップロード
 * @param {*} chunk 
 * @param {*} counter 
 */
async function UploadChunk(chunk, counter) {
	let response = null;
	for (let i = 0; i < retries; i++) {
		response = await axios({
			method: 'POST',
			url: variables.FILEUPLOAD_URL + "/UploadChunks",
			data: chunk,
			params: { id: counter, fileName: fileName },
			headers: { "Content-Type": "application/octet-stream", 'company': urlCompanyName },
			withCredentials: true
		}).catch((error) => {
			appInsights.trackException({ ...error, errorFunction: "commonFun.UploadChunk()" });
		});
		if (response) {
			// break the loop
			return response;
		}
	}
	return response;
};

/**
 * アップロード プロセスが完了すると、各チャンクがマージ
 */
async function UploadCompleted(yuukoukikan) {
	let objUrl = null;
	var formData = new FormData();
	formData.append("fileName", fileName);
	let response = await axios({
		method: 'POST',
		url: variables.FILEUPLOAD_URL + "/UploadComplete",
		params: { fileName: fileName, fileSize: fileSize, yuukoukikan: yuukoukikan },
		data: formData,
		headers: { 'company': urlCompanyName },
		withCredentials: true
	}).catch((error) => {
		appInsights.trackTrace({ data: JSON.stringify(formData) });
		appInsights.trackException({ ...error, errorFunction: "commonFun.UploadCompleted()" });
		throw (error.response.status);
	});
	if (response) {
		const data = response.data;
		if (data.isSuccess) {
			objUrl = data.objUrl;
		} else {
			throw (data.errorMessage);
		}
	}
	return objUrl;
};

/**
 * 認証チェック(会社ログイン済みか又はユーザーログイン済みかをチェックする)
 */
export async function Validate(urlpath) {
	await axios({
		method: 'GET',
		url: variables.LOGIN_URL + "/validate",
		headers: { 'company': urlCompanyName },
		withCredentials: true
	}).then(function (response) {
		if (urlpath === UrlPath.Editor) {
			if (response.data.statusCode !== StatusCode.AuthorizedWithAdministrator) {
				const error = 401;
				throw (error);
			}
		}
		else {
			if (response.data.statusCode !== StatusCode.AuthorizedWithAdministrator) {
				if (response.data.statusCode !== StatusCode.Authenticated) {
					const error = 401;
					throw (error);
				}
			}
		}
	}).catch(function (error) {
		appInsights.trackException({ ...error, errorFunction: "commonFun.Validate()" });
		throw (error);
	});
};

let isEditing = false;

export function IsEditing() {
	return isEditing;
}

export function SetIsEditing(val) {
	isEditing = val;
}

/**
 * 会社ログイン画面に移動する。
 * @param {*} navigate 
 */
export function NavigateToCompanyLogin(navigate) {
	navigate(generatePath(CompanyLoginPath, {
		company: urlCompanyName
	}), { replace: true });
}

/**
 * ユーザーログイン画面に移動する。
 * @param {*} navigate 
 */
export function NavigateToUserLogin(navigate) {
	navigate(generatePath(UserLoginPath, {
		company: urlCompanyName
	}), { replace: true });

}

/**
 * バージョン履歴画面へ移動する。
 * @param {*} navigate 
 */
export function NavigateToVersion(navigate) {
	navigate(generatePath("/:company/version", {
		company: urlCompanyName
	}), { replace: true });
}

/**
 * 作業開始画面へ移動する。
 * @param {*} navigate 
 * @param {*} lotOperationLogLockId
 */
export function NavigateToOperationStartWork(navigate, lotOperationLogLockId) {
	navigate(generatePath(OperationStartWorkPath, {
		company: urlCompanyName
	}), { state: lotOperationLogLockId, replace: true });
}

/**
 * オペレーションログイン画面へ移動する。
 * @param {*} navigate 
 */
export function NavigateToOperationLogin(navigate) {
	navigate(generatePath(OperationLoginPath, {
		company: urlCompanyName
	}), { replace: true });
}

/**
 * オペレーション画面へ移動する。
 * @param {*} navigate 
 * @param {*} dataObj 
 */
export function NavigateToOperation(navigate, dataObj) {
	navigate(generatePath(OperationPath, {
		company: urlCompanyName
	}), {
		replace: true,
		state: dataObj
	});
}

/**
 * urlパスから会社名を取得する。
 * @returns 
 */
export function GetUrlCompany() {
	try {
		return window.location.pathname.split('/')[1];
	} catch (error) {
		return "";
	}
}

export const MeasureText = (pText, pFontSize, tdWidth) => {
	var lDiv = document.createElement('div');
	document.body.appendChild(lDiv);
	lDiv.style.fontSize = "" + pFontSize + "px";
	lDiv.style.position = "absolute";
	lDiv.style.left = -1000;
	lDiv.style.top = -1000;
	lDiv.textContent = pText;
	let overWidth = (lDiv.clientWidth + 1) > tdWidth;
	document.body.removeChild(lDiv);
	lDiv = null;
	return overWidth;
}

/**
 * ロットオペレーションの作業登録処理 
 * @param {*} data 
 * @param {*} navigate 
 * @returns 
 */
export async function RegisterLotOperationLog(data, navigate) {
	let result = { isFailed: false, data: null };
	await axios({
		method: 'POST',
		url: variables.OPERATION_URL + "/register-lot-operation-log",
		withCredentials: true,
		headers: { 'company': urlCompanyName },
		data: data
	}).then(function (response) {
		result.data = response.data;
		result.isFailed = response.data.errorCode > 0 ? true : false;
	}).catch(function (error) {
		result.isFailed = true;
		let errResponseStatus = error.response.status;
		if (errResponseStatus === 401) {
			NavigateToOperationLogin(navigate);
		}
		else {
			appInsights.trackTrace({ data: JSON.stringify(data) });
			appInsights.trackException({ ...error, errorFunction: "commonFun.RegisterLotOperationLog()" });
			console.error(error);
		}
	});
	return result;
}

/**
 * シリアルオペレーションの作業登録処理 
 * @param {*} data 
 * @param {*} navigate 
 * @returns 
 */
export async function RegisterSerialOperationLog(data, navigate) {
	let result = { isFailed: false, data: null };
	await axios({
		method: 'POST',
		url: variables.OPERATION_URL + "/register-serial-operation-log",
		withCredentials: true,
		headers: { 'company': urlCompanyName },
		data: data
	}).then(function (response) {
		result.data = response.data;
		result.isFailed = response.data.errorCode > 0 ? true : false;
	}).catch(function (error) {
		result.isFailed = true;
		let errResponseStatus = error.response.status;
		if (errResponseStatus === 401) {
			NavigateToOperationLogin(navigate);
		}
		else {
			console.error(error);
			appInsights.trackTrace({ data: JSON.stringify(data) });
			appInsights.trackException({ ...error, errorFunction: "commonFun.RegisterSerialOperationLog()" });
		}
	});
	return result;
}

/**
 * バッチサイズ変更の登録処理
 * @param {*} data 
 * @param {*} navigate 
 * @returns 
 */
export async function RegisterMatomesuuChangeLog(data, navigate) {
	let result = { isFailed: false, data: null };
	await axios({
		method: 'POST',
		url: variables.OPERATION_URL + "/register-matomesuu-change-log",
		withCredentials: true,
		headers: { 'company': urlCompanyName },
		data: data
	}).then(function (response) {
		result.data = response.data;
		result.isFailed = response.data.errorCode > 0 ? true : false;
	}).catch(function (error) {
		result.isFailed = true;
		let errResponseStatus = error.response.status;
		if (errResponseStatus === 401) {
			NavigateToOperationLogin(navigate);
		}
		else {
			appInsights.trackTrace({ data: JSON.stringify(data) });
			appInsights.trackException({ ...error, errorFunction: "commonFun.RegisterMatomesuuChangeLog()" });
			console.error(error);
		}
	});
	return result;
}

/**
 * データーバインディングDateフォーマット変更
 * @param {*} date 
 * @returns 
 */
export function ConvertDateFormat(dateValue) {
	let result = "";
	let date = new Date(dateValue);
	if (date) {
		result = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, 0)}-${date.getDate().toString().padStart(2, 0)}`;
	}
	return result;
}

/**
 * データーバインディングDateTimeフォーマット変更
 * @param {*} date 
 * @returns 
 */
export function ConvertDateTimeFormat(dateValue) {
	let result = "";
	let date = new Date(dateValue);
	if (date) {
		result = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, 0)}-${date.getDate().toString().padStart(2, 0)} ${date.getHours().toString().padStart(2, 0)}:${date.getMinutes().toString().padStart(2, 0)}`;
	}
	return result;
}

/**
 * データーバインディングHH:MMフォーマット変更
 * @param {*} date 
 * @returns 
 */
export function ConvertTimeFormat(dateValue) {
	let result = "";
	let date = new Date(dateValue);
	if (date) {
		result = `${date.getHours().toString().padStart(2, 0)}:${date.getMinutes().toString().padStart(2, 0)}`;
	}
	return result;
}

/**
 * 
 * @param {*} dateValue 
 * @param {*} days 
 */
export function GetLastorNextDays(dateValue, days) {
	var endDate = "";
	var count = 0;
	var dayCount = Math.abs(days);
	while (dayCount > count) {
		//Get Next Days
		if (days > 0) {
			endDate = new Date(dateValue.setDate(dateValue.getDate() + 1));
		}
		//Get last days
		else {
			endDate = new Date(dateValue.setDate(dateValue.getDate() - 1));
		}

		if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
			count++;
		}
	}
	return endDate;
}

/**
 * 管理項目の回数設定について、バッチサイズ×割合を小数点第一位で 六捨七入
 * 最小値は「1」
 * @param {*} manageItemType 
 * @param {*} description 
 * @param {*} batchSize 
 * @returns 
 */
export function GetKaisuuCheckCountWithRoundUpAndDown(manageItemType, description, batchSize) {
	let result = 0;
	if (manageItemType === ManageItemType.Hardware) {
		result = description.hardwareKurikaeshi === true ? batchSize : 1;
	}
	else if (description.hindoKaisuSetting?.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) {
		if (description.hindoKaisuSetting.kaisuSetting.batchSize === true) {
			result = batchSize;
		}
		if (description.hindoKaisuSetting.kaisuSetting.kotei === true) {
			const koteiData = description.hindoKaisuSetting.kaisuSetting.koteiData;
			result = koteiData > batchSize ? batchSize : koteiData;
		}
		if (description.hindoKaisuSetting.kaisuSetting.wariai === true) {
			const wariaiData = description.hindoKaisuSetting.kaisuSetting.wariaiData;
			let perValue = (batchSize * wariaiData) / 100;
			let integerPart = Math.floor(perValue);
			let decimalPart = perValue - integerPart;
			// 小数点以下1桁に丸めた文字列に変換し、その結果の文字列を浮動小数点数に変換する
			decimalPart = parseFloat(decimalPart.toFixed(1));
			if (decimalPart > 0.6) {
				// 切り上げて整数に変換する
				result = Math.ceil(perValue);
			}
			else if (decimalPart < 0.7) {
				// 切り捨てて整数に変換する
				result = Math.floor(perValue);
			}
		}
	}
	return result > 0 ? result : 1;
}